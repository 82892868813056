const Footer = () => {
  return (
    <footer>
      <div className="copyright">
        <p>
          Made by <span>Sohee</span>
        </p>
        <p className="date">(C) 2024 jeongsohee.com</p>
      </div>
    </footer>
  );
};

export default Footer;
