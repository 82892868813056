import { HashRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main";
import Daesang from "./pages/work/daesang";
import DongaSI from "./pages/work/donga-si";
import DongaSM from "./pages/work/donga-sm";
import SamsungSI from "./pages/work/samsung";
import DefaultLayout from "./layout/defaultLayout";
import Develog from "./pages/projects/Develog";
import Origami from "./pages/projects/Origami";
import "./style/css/reset.css";
import "./style/css/style.css";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultLayout>
              <Main />
            </DefaultLayout>
          }
        />
        <Route
          path="/daesang"
          element={
            <DefaultLayout>
              <Daesang />
            </DefaultLayout>
          }
        />
        <Route
          path="/dongasi"
          element={
            <DefaultLayout>
              <DongaSI />
            </DefaultLayout>
          }
        />
        <Route
          path="/dongasm"
          element={
            <DefaultLayout>
              <DongaSM />
            </DefaultLayout>
          }
        />
        <Route
          path="/scompanysi"
          element={
            <DefaultLayout>
              <SamsungSI />
            </DefaultLayout>
          }
        />
        <Route
          path="/develog"
          element={
            <DefaultLayout>
              <Develog />
            </DefaultLayout>
          }
        />
        <Route
          path="/origami"
          element={
            <DefaultLayout>
              <Origami />
            </DefaultLayout>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
