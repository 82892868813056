const Header = () => {
  return (
    <header>
      <div className="header-inner">
        <div className="header-top">
          <h3>Sohee's Web Portfolio</h3>
          <a className="mail" href="mailto:toy3045@gmail.com">
            toy3045@gmail.com
          </a>
        </div>
        <div className="header-bottom">
          탄탄한 코드를 바탕으로 발전하고 싶은 프론트엔드 개발자 🛠️
        </div>
      </div>
    </header>
  );
};

export default Header;
