const Contact = () => {
  return (
    <article className="contact-cont">
      <div className="contact-inner line">
        <p className="pc-txt effect">함께 일할 프론트개발자를 찾고계신가요?</p>
        <div className="mobile-txt">
          <p>
            <span className="effect">함께 일할 프론트개발자를</span>
            <br />
            <span className="effect">찾고계신가요?</span>
          </p>
        </div>
        <a href="mailto:toy3045@gmail.com">메일 보내기</a>
      </div>
    </article>
  );
};

export default Contact;
