import React, { useEffect } from "react";
import Contact from "../../components/contact";
import DevelogMain from "../../style/img/develog_main.gif";
import DevelogImg from "../../style/img/develog_img.png";
import DevelogLogin from "../../style/img/develog_login.gif";
import { io } from "../../animation";

const Develog = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>project detail</h3>
          <h2>Develog 프로젝트</h2>
          <div className="sub-info wrap">
            <span className="date">
              코어 개발 시간 : 2024. 07. 08 - 2024. 07. 30 (개발 17일, 기획 7일,
              총 3주)
            </span>
            <span>
              #Javascript #React #Jotai #Chart.js #Vite #Node.js #MongoDB
              #Express #Mongoose #Netlify #Render #Vitest #Supertest
            </span>
          </div>
        </div>
        <div className="work-cont">
          <div className="work-visual effect h-auto">
            <img src={DevelogMain} alt="Develog 플레이영상" />
          </div>
          <div className="work-info">
            <p className="effect">
              Develog는 개발팀 생산성 향상 도구입니다. DORA (DevOps Research and
              Assessment) Metrics를 주요 지표로 사용하여 개발 과정의 비효율성을
              데이터 분석으로 식별하고 개선하여 팀의 생산성과 프로젝트 관리
              효율성을 높이기 위해 만들었습니다.
              <div className="visual-detail effect login">
                <img src={DevelogLogin} alt="Develog이미지" />
              </div>
              <br />
              <span className="highlight">
                Github OAuth 2.0 연동을 통한 로그인 기능 구현과 사용자 세션
                관리를 위한 <br />
                JWT 기반 인증 시스템 구축을 하였습니다.
              </span>
            </p>
          </div>
          <div className="visual-detail logo effect cover">
            <img src={DevelogImg} alt="Develog이미지" />
          </div>
          <div className="work-info">
            <p className="effect">
              DORA Metrics 지표에 필요한 데이터를 효율적으로 추출하기 위해
              <br />
              컬렉션 간 관계를 표현하는 참조 패턴 적용하였고
              <br />
              <span className="highlight">
                중복 데이터의 반복되는 네트워크 요청을 전역 상태 관리를 통해
                처리하였습니다.
              </span>
              <br />
              <br />
              또한 정기적 API 호출 대신{" "}
              <span className="highlight">
                웹훅을 활용한 자동 데이터 갱신 기능 구현하였고
              </span>
              <br />
              데이터 갱신을 위한 GitHub API 호출 실패 시, 재호출 메커니즘
              구현하였습니다.
            </p>
          </div>
        </div>
      </article>
      <div className="button-wrap">
        <a href="../index.html" className="back">
          돌아가기
        </a>
        <a
          href="https://github.com/Develog-Tools-for-team-productivity/Develog"
          className="project"
        >
          프로젝트 둘러보기
        </a>
      </div>
      <Contact />
    </main>
  );
};

export default Develog;
