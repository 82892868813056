import React, { useEffect } from "react";
import Contact from "../../components/contact";
import dongaPay from "../../style/img/donga_video2.gif";
import dongaLogo from "../../style/img/donga_logo.png";
import { io } from "../../animation";

const DongaSM = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>work detail</h3>
          <h2>동아제약 DAPmall 유지보수</h2>
          <div className="sub-info">
            <span className="date">2023년</span>
            <span>#html #css</span>
          </div>
        </div>
        <div className="work-cont">
          <div className="work-visual effect">
            <img src={dongaPay} alt="동아유지보수동영상" />
          </div>
          <div className="work-info">
            <p className="effect">
              동아제약 B2B와 BO 웹 서비스의 UI 구축 후 더 안정적인 운영을 위해{" "}
              <span className="highlight">
                유지보수 및 개선 작업을 진행하였고 새로운 기능의 UI 작업을
                맡았습니다.
              </span>{" "}
              개발팀과 함께 작업하며 지식을 공유하여 보다 효율적이고 나은 코드를
              작성 할 수 있었습니다.
            </p>
          </div>
          <div className="visual-detail effect dongalogo">
            <img src={dongaLogo} alt="동아로고" />
          </div>
          <div className="work-info">
            <p className="effect">
              서비스 오픈 후 운영 중 개선이 필요한{" "}
              <span className="highlight">
                UI 개선작업을 담당하였고 BO와 예약결제 신규 개발 작업을 진행하며
                다양한 케이스에 경험하였습니다.
              </span>{" "}
              또한 고객사 요청에 따라 IE 대응을 안정적으로 하여 UI가 무너지지
              않도록 개선하였습니다. 다양한 유지보수 업무에 참여하며 고객사
              요청과 각종 오류에 대응하여 서비스의 완성도를 높였습니다.
            </p>
          </div>
        </div>
      </article>
      <a href="../index.html" className="back">
        돌아가기
      </a>
      <Contact />
    </main>
  );
};

export default DongaSM;
