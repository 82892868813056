import React, { useEffect } from "react";
import Contact from "../../components/contact";
import OrigamiMain from "../../style/img/origami_main.gif";
import OrigamiImg from "../../style/img/origami_img.jpg";
import OrigamiFold from "../../style/img/readme.gif";
import { io } from "../../animation";

const Origami = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>project detail</h3>
          <h2>Origami:3D 프로젝트</h2>
          <div className="sub-info wrap">
            <span className="date">
              코어 개발 시간 : 2024. 06. 11 - 2024. 07. 02 (개발 15일, 기획 7일,
              총 3주)
            </span>
            <span>#Javascript #Three.js #Vite #Firebase #Netlify #Vitest</span>
          </div>
        </div>
        <div className="work-cont">
          <div className="work-visual effect h-auto">
            <img src={OrigamiMain} alt="오리가미 플레이영상" />
          </div>
          <div className="work-info">
            <p className="effect">
              Origami:3D는 아날로그 종이접기를 웹으로 구현한 종이접기 3D
              시뮬레이션 입니다.
              <br />
              <br />
              종이가 접히는 선을 생성하기 위해 벡터 개념 적용하였고 종이가
              접히는 선을 그리는 과정에서 선이 무한정 뻗어나가는 것을 방지하기
              위해{" "}
              <span className="highlight">
                Convex Hull 알고리즘 활용하였습니다.
              </span>
              <br />
              그리고 기존의 Convex Hull 알고리즘에
              <br />
              <span className="highlight">
                분할 정복 알고리즘을 적용하여 시간복잡도 최적화하였습니다.
              </span>
            </p>
          </div>
          <div className="visual-detail logo effect cover">
            <img src={OrigamiFold} alt="접는 이미지" />
          </div>
          <div className="work-info">
            <p className="effect">
              접히는 영역의 외곽선을 표시하기 위해 Three.js 라이브러리의 3D 메시
              활용하였습니다.
              <br />
              <div className="visual-detail logo effect cover">
                <img src={OrigamiImg} alt="로고이미지" />
              </div>
              <br />
              <span className="highlight">
                로딩 속도 향상을 위해 Firebase 스토리지에서 청크화된 3D 모델
                데이터를
                <br />
                비동기 로드하여 병렬적으로 처리하였습니다.
              </span>
              <br />
              <br />
              그리고 사용자 기반 피드백을 통해 다음 세가지를 추가
              구현하였습니다.
              <br />
              사용자가 버튼의 기능을 쉽게 이해하고 사용할 수 있도록 마우스 오버
              시<br /> 동작을 설명하는툴팁을 공통 적용, 사용자가 3D 종이 모델을
              다양한 각도에서
              <br /> 쉽게 관찰할 수 있도록 카메라 조작법에 대한 가이드 추가,
              사용자에게 로딩 진행 상황을
              <br /> 시각적으로 알리기 위해 재사용성을 고려한 로딩 컴포넌트 구현
              <br />
            </p>
          </div>
        </div>
      </article>
      <div className="button-wrap">
        <a href="../index.html" className="back">
          돌아가기
        </a>
        <a href="https://github.com/Origami-5M/Origami" className="project">
          프로젝트 둘러보기
        </a>
      </div>
      <Contact />
    </main>
  );
};

export default Origami;
