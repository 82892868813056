import { Link } from "react-router-dom";

const WorkCard = ({ link, type, title, tag, img }) => {
  return (
    <Link to={link}>
      <div className="works effect">
        <div className="works-title">
          <span>{type}</span>
          <h3>{title}</h3>
          <p>{tag}</p>
        </div>
        <div className="works-img">
          <img src={img} alt="workImage" />
        </div>
      </div>
    </Link>
  );
};

export default WorkCard;
