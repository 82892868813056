import React, { useEffect } from "react";
import Contact from "../../components/contact";
import reactImg from "../../style/img/react_img.png";
import { io } from "../../animation";

const SamsungSI = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>work detail</h3>
          <h2>S사 마크업 개발</h2>
          <div className="sub-info">
            <span className="date">2023년</span>
            <span>#React #Javascript #html #css</span>
          </div>
        </div>
        <div className="work-cont">
          <div className="visual-detail mobile effect react_logo">
            <img src={reactImg} alt="리액트로고" />
          </div>
          <div className="work-info">
            <p className="effect">
              S사 프로젝트의 마크업 개발에 참여하였습니다.
              <br /> React를 활용하여 마크업 작업을 진행하였습니다.
            </p>
          </div>
          <div className="work-info">
            <p className="effect">
              S사 프로젝트를 진행하며{" "}
              <span className="highlight">
                React 개발환경세팅과 useState를 사용하여 컴포넌트에서 상태
                관리해보았습니다. 또한 react-router-dom으로 SPA 사용자 경험
                향상을 목적으로 두었고 Redux를 사용하여 전역 상태관리를 해보는
                경험을 쌓을 수 있었습니다.
              </span>
            </p>
          </div>
        </div>
      </article>
      <a href="../index.html" className="back">
        돌아가기
      </a>
      <Contact />
    </main>
  );
};

export default SamsungSI;
