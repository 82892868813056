const observerOptions = {
  root: null,
  threshold: 0.2,
  rootMargin: "-0px",
};

const io = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("visible");
    } else {
      entry.target.classList.remove("visible");
    }
  });
}, observerOptions);

document.querySelectorAll(".effect").forEach((wrapper) => io.observe(wrapper));

export { observerOptions, io };
