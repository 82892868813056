import React, { useEffect } from "react";
import Contact from "../../components/contact";
import DaesangImg from "../../style/img/portfolio_daesang.png";
import DaesangLogoImg from "../../style/img/daesang_logo.jpeg";
import { io } from "../../animation";

const Daesang = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>work detail</h3>
          <h2>대상웰라이프 디자인 및 마크업 개발</h2>
          <div className="sub-info">
            <span className="date">2021년-2022년</span>
            <span>#html #css #Photoshop</span>
          </div>
        </div>
        <div className="work-cont">
          <div className="work-visual effect">
            <img src={DaesangImg} alt="대상홈페이지이미지" />
          </div>
          <div className="work-info">
            <p className="effect">
              대상웰라이프 환자식 건강식품 쇼핑몰로 임직원몰과 B2C로 구축되어
              기존 서비스의 안정적인 운영을 위해 유지보수 업무를 담당했으며{" "}
              <span className="highlight">
                고객사의 개선요청에 따라 포토샵을 활용하여 사용자 경험을
                바탕으로 디자인 작업부터 마크업 작업까지 참여하였습니다.
              </span>
            </p>
          </div>
          <div className="visual-detail logo effect">
            <img src={DaesangLogoImg} alt="대상홈페이지이미지" />
          </div>
          <div className="work-info">
            <p className="effect">
              UI 개선 작업 완료 후{" "}
              <span className="highlight">
                크로스브라우징하여 각각의 브라우저마다 생기는 오류를 찾아내여
                최적화 하였습니다.
              </span>{" "}
              또한 오랫동안 운영되어 온 서비스이다 보니 유지보수 작업 중
              발견되는 중복코드를 최대한 제거하여 다음 유지보수 시 효율적으로
              작업할 수 있도록 개선하였습니다.
            </p>
          </div>
        </div>
      </article>
      <a href="../index.html" className="back">
        돌아가기
      </a>
      <Contact />
    </main>
  );
};

export default Daesang;
