import React, { useEffect } from "react";
import Contact from "../../components/contact";
import dongaMo from "../../style/img/donga_mobile.gif";
import dongaLogo from "../../style/img/donga_logo.png";
import { io } from "../../animation";

const DongaSI = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  return (
    <main>
      <article className="work-detail">
        <div className="work-title effect">
          <h3>work detail</h3>
          <h2>동아제약 DAPmall 디자인 및 마크업 개발</h2>
          <div className="sub-info">
            <span className="date">2022년-2023년</span>
            <span>#html #css #Figma</span>
          </div>
        </div>
        <div className="work-cont">
          <div className="visual-detail effect dongalogo">
            <img src={dongaLogo} alt="동아로고" />
          </div>
          <div className="work-info">
            <p className="effect">
              동아제약과 약국의 거래 서비스를 기반 웹 사이트로 이커머스 운영에
              효율적으로 개발 되었습니다.{" "}
              <span className="highlight">
                Figma를 활용하여 UX/UI를 바탕으로 디자인하였으며, 이메일템플릿
                및 모바일 마크업 개발에 참여하였습니다.
              </span>
            </p>
          </div>
          <div className="visual-detail effect">
            <img src={dongaMo} alt="동아피그마디자인동영상" />
          </div>
          <div className="work-info">
            <p className="effect">
              구축부터 유지보수까지 참여한 프로젝트로 모바일 디자인부터
              마크업작업까지 담당하였으며 여러 팀원과 함께 보다 완성도 높은
              사이트를 만들기 위해 서로 의견을 교환하며 협업하였습니다.
              프로젝트를 진행하며{" "}
              <span className="highlight">
                datepicker 등 다양한 라이브러리를 활용하여 편리하고 효율적으로
                UI를 개발 할수 있었고 적절한 라이브러리 사용 경험을 쌓을 수
                있었습니다.
              </span>{" "}
              직접 디자인에 참여하여 더욱 효율적이고 빠르게 마크업 개발이 가능할
              수 있었고 덕분에 개선사항도 빠르게 검토하여 완성도 높은 서비스를
              제공 할 수 있었습니다.
            </p>
          </div>
        </div>
      </article>
      <a href="../index.html" className="back">
        돌아가기
      </a>
      <Contact />
    </main>
  );
};

export default DongaSI;
