import React, { useEffect } from "react";
import WorkCard from "../components/card/workCard";
import Contact from "../components/contact";
import ProfileImg from "../style/img/profile.png";
import { io } from "../animation";

const Main = () => {
  useEffect(() => {
    const elementsToObserve = document.querySelectorAll(".effect");
    elementsToObserve.forEach((element) => {
      io.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        io.unobserve(element);
      });
    };
  }, []);

  const workCardList = [
    {
      link: "/scompanysi",
      type: "MARKUP",
      title: "S사 마크업 개발",
      tag: "#css #Javascript #React",
      img: require("../style/img/Group 1.png"),
    },
    {
      link: "/dongasm",
      type: "MARKUP",
      title: "동아제약 DAPmall 웹퍼블리싱 유지보수",
      tag: "#html #css",
      img: require("../style/img/Group 3.png"),
    },
    {
      link: "/dongasi",
      type: "MARKUP & Design",
      title: "동아제약 DAPmall 디자인 및 마크업 개발",
      tag: "#html #css #Figma",
      img: require("../style/img/Group 2.png"),
    },
    {
      link: "/daesang",
      type: "MARKUP & Design",
      title: "대상웰라이프 디자인 및 웹퍼블리싱 유지보수",
      tag: "#html #css #Photoshop",
      img: require("../style/img/Group 1.png"),
    },
  ];

  const projectsCardList = [
    {
      link: "/develog",
      type: "개인 프로젝트",
      title: "Develog 프로젝트",
      tag: "#React #Jotai #Node.js #MongoDB",
      img: require("../style/img/Group 1.png"),
    },
    {
      link: "/origami",
      type: "팀 프로젝트",
      title: "Origami:3D 프로젝트",
      tag: "#Javascript #Three.js #Vite #Firebase",
      img: require("../style/img/Group 1.png"),
    },
  ];

  return (
    <div className="container">
      <main>
        <article className="main-cont">
          <div className="main-inner">
            <div className="main-left">
              <h2>탄탄한 코드를 바탕으로 발전하고 싶은</h2>
              <h1 className="line">
                <span className="effect">프론트엔드 개발자</span>
                <br />
                <span className="effect">정소희</span>
              </h1>
              <p>
                기본이 탄탄한 코드를 바탕으로 다양한 기술을 배우고 접목시켜
                사용하기 쉬운 UX와 감각적인 UI로 사용자들에게 기억이 남는
                서비스를 개발하고 싶습니다. 오늘보다 내일 더 나은 프론트개발자로
                나아가자는 마음가짐으로 임하고 있습니다.
              </p>
            </div>
            <div className="main-right">
              <img src={ProfileImg} alt="정소희프로필이미지" />
            </div>
          </div>
        </article>
        <article className="about-cont">
          <div className="about-inner">
            <div className="about-txt">
              <h3 className="effect">
                ABOUT <strong>SOHEE</strong>
              </h3>
              <p className="effect">
                안녕하세요! 프론트개발자 정소희입니다.
                <br />
                오늘보다 내일 더 나은 프론트개발자로 나아가자는 마음으로
                발전하고 있습니다.
              </p>
              <p className="effect">
                웹 접근성과 웹 표준을 고려한 웹 페이지를 그려내고 원활한 소통을
                바탕으로
                <br /> 다양한 직군과 함께 맡은 프로젝트에 최선을 다해 임하고
                멋진 결과물을 만들기 위해 노력하겠습니다.
              </p>
            </div>
            <div className="about-bottom effect">
              <div className="about-edu">
                <h3>EDUCATION & CERTIFICATE</h3>
                <ul>
                  <li>
                    <span>2022-2024</span> 서울사이버대학교 컴퓨터공학과 졸업
                  </li>
                  <li>
                    <span>2024</span> 바닐라코딩 프론트엔드과정 수료
                  </li>
                  <li>
                    <span>2022</span> 웹디자인기능사
                  </li>
                  <li>
                    <span>2021</span> GTQ 1급 포토샵
                  </li>
                  <li>
                    <span>2021</span> GTQ 1급 일러스트레이터
                  </li>
                  <li>
                    <span>2020-2021</span> UI.UX 디자인&퍼블리싱 교육 이수
                  </li>
                </ul>
              </div>
              <div className="about-exp">
                <h3>WORKS</h3>
                <ul>
                  <li>
                    <span>2023</span>S사 리액트 마크업 개발
                  </li>
                  <li>
                    <span>2023</span>동아제약 DAPmall 웹퍼블리싱 유지보수
                  </li>
                  <li>
                    <span>2022-2023</span>동아제약 DAPmall 디자인 및 마크업 개발
                  </li>
                  <li>
                    <span>2022-2023</span>베이비본죽 웹퍼블리싱 유지보수
                  </li>
                  <li>
                    <span>2021-2022</span>대상웰라이프 디자인 및 웹퍼블리싱
                    유지보수
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
        <article className="works-cont">
          <div className="works-inner">
            {workCardList.map((v, i) => (
              <WorkCard
                key={i}
                link={v.link}
                type={v.type}
                title={v.title}
                tag={v.tag}
                img={v.img}
              />
            ))}
          </div>
        </article>
        <article className="works-cont projects-cont">
          <div className="works-inner">
            {projectsCardList.map((v, i) => (
              <WorkCard
                key={i}
                link={v.link}
                type={v.type}
                title={v.title}
                tag={v.tag}
                img={v.img}
              />
            ))}
          </div>
        </article>
        <Contact />
      </main>
    </div>
  );
};

export default Main;
